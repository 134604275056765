import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MENU_KAUF, MENU_VERKAUF } from '../constants';

import PageDialog from '../components/Dialogs/PageDialog';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import Linkitem from '../components/Linkitems/Linkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import ImmoCardAbgeber from '../components/Cards/ImmoCardAbgeber';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import FormSelect from '../components/FormFields/FormSelect';

import '../styles/immodrive.scss';
import { downloadZip } from '../util/generalUtils';
import { downloadDoc } from '../util/generalUtils';

// ICONS
import { getRealtyFile } from '../api/JustImmo';
import ChecklistTableSingle from '../components/ChecklistTable/ChecklistTableSingle';
import IconHerunterladen from '../components/Icons/IconHerunterladen';
import IconImmothek from '../components/Icons/IconImmothek';
import IconDocument from '../components/Icons/IconDocument';

import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
import SelectIhreImmobilie from '../components/Header/SelectIhreImmobilie';

function Immodrive() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const { files, getKaeuferImmoPhase3, reloadData } = useHandleUserRealtyActive();

  const [immoCardAbgeberRealty, setImmoCardAbgeberRealty] = useState(null);

  const [downloadError, setDownloadError] = useState('');
  const [checklistTableOptions, setChecklistTableOptions] = useState([]);

  //console.log(user.userRealties);

  const immoOptions = user.userRealties.map((immo) => {
    return {
      id: immo.id,
      text: immo.title,
    };
  });

  useEffect(() => {
    if (app.menuType === MENU_KAUF) {
      let kaeuferPhase3Immo = getKaeuferImmoPhase3();
      if (kaeuferPhase3Immo?.id > 0) {
        setImmoCardAbgeberRealty(kaeuferPhase3Immo);
      }
    } else {
      if (user.userRealtyActive?.id > 0) {
        setImmoCardAbgeberRealty(user.userRealtyActive);
      }
    }

    if (files.length > 0) {
      let childOptionsHelper = [];
      childOptionsHelper = files.map((file) => {
        return {
          label: file.headline,
          checked: true,
          item: { ...file, immo: user.userRealtyActive },
        };
      });

      setChecklistTableOptions(childOptionsHelper);

      // TODO: add categories for parentLabel (NTX-Immodrive)
      // let childOptions = [];
      // childOptions.push({
      //   parentLabel: 'Meine Doks',
      //   childList: childOptionsHelper,
      // });

      // setChecklistTableOptions(childOptions);
    }
  }, []);

  useEffect(() => {
    reloadData();
  }, [user.userRealtyActive]);

  const { isMobile } = useHandleScreenResize();

  const [showAllDoksPageDialog, setShowAllDoksPageDialog] = useState(false);
  const handleSetShowAllDoksPageDialog = () => {
    setShowAllDoksPageDialog(!showAllDoksPageDialog);
    setDownloadError('');

    // set items.checked to true on closing/opening
    if (files.length > 0) {
      let childOptionsHelper = files.map((file) => {
        return {
          label: file.headline,
          checked: true,
          item: { ...file, immo: user.userRealtyActive },
        };
      });

      setChecklistTableOptions(childOptionsHelper);
    }
  };

  const handleDownloadZip = async (all = false) => {
    let docs = [];
    setDownloadError('');

    if (all) {
      docs = files.map((file) => {
        return { ...file, immo: user.userRealtyActive };
      });
    } else {
      // TODO: get items from childlist (NTX-Immodrive)
      // docs = checklistTableOptions[0].childList
      //   .filter((item) => item.checked)
      //   .map((item) => {
      //     return item.item;
      //   });

      const checkboxes = document.querySelectorAll('#immodrive-files > .MuiFormControlLabel-root');
      let checkedItemNames = [];

      checkboxes.forEach(function (label) {
        let checkitem = label.querySelector('.MuiCheckbox-root');
        let labelName = label.querySelector('.MuiFormControlLabel-label').innerHTML;
        let isChecked = checkitem.classList.contains('Mui-checked');
        if (isChecked && labelName) {
          checkedItemNames.push(labelName);
        }
      });

      // add file to docs-array
      if (checkedItemNames) {
        docs = files
          .filter((f) => checkedItemNames.includes(f.headline))
          .map((f) => {
            return {
              ...f,
              immo: user.userRealtyActive,
            };
          });
      }
    }

    if (docs.length === 0) {
      setDownloadError('Bitte wählen Sie Dokumente für den Download aus.');
    } else {
      await downloadZip(docs, 'immo-drive');
      handleSetShowAllDoksPageDialog();
    }
  };

  const downloadDocJustImmo = async (file) => {
    try {
      const realtyFile = await getRealtyFile(user.userRealtyActive.id, user.userRealtyActive.tenant_id, file.storage_key);
      const url = window.URL.createObjectURL(realtyFile);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.original_filename;
      a.click();
    } catch (e) {
      //handle realty file error
      console.error('e', e);
    }
  };

  const midIndex = Math.ceil(files.length / 2);
  const firstHalf = files.slice(0, midIndex);
  const secondHalf = files.slice(midIndex);

  const [ihreImmobilien, setIhreImmobilien] = useState([]);

  useEffect(() => {
    if (user.userRealties.length > 0) {
      let immos = user.userRealties
        .filter((r) => r !== null)
        .map((r) => {
          if (!r.id || !r.title) return null;

          return {
            id: r.id,
            text: r.title,
            immo: r,
          };
        })
        .filter((r) => r !== null);

      setIhreImmobilien(immos);
    }
  }, [user.userRealties]);

  return (
    <>
      <MetaTags title={'Immodrive | Die Online-Plattform für Ihre Immobilie'} description={'Hier finden Sie alle Dokumente, die Ihre Objekte betreffen'} />
      <HeaderSmallWithIcon icon={<IconImmothek />} iconActive={true} headlineDesktop="immo-drive" cssClasses="header--turquoisebg" />

      <MainContent cssClasses="immo-drive">
        <h1 className="headline--mobile w-desktop--center mb-0">immo-drive</h1>

        {immoCardAbgeberRealty && app.menuType === MENU_VERKAUF && (
          <div className="mt-0 gray-background">
            <div className="pt-200rem pb-200rem w-desktop--center">
              <div className="immo-drive__immo-header">
                <h2>Meine Dokumente für</h2>
                <FormSelect
                  showBitteWaehlen={false}
                  options={ihreImmobilien}
                  id="userRealties"
                  formType="userRealties"
                  value={user.userRealtyActive?.id ? { id: user.userRealtyActive.id, text: user.userRealtyActive.title } : ''}
                  showResetIcon={false}
                  noRedirect={true}
                />
              </div>
              <ImmoCardAbgeber removeButton={true} retailObject={user.userRealtyActive} key={user.userRealtyActive.id} />
            </div>
          </div>
        )}
        {isMobile && files.length > 0 && (
          <div className="w-desktop--center">
            <h2>Meine Dokumente</h2>
            <div className="d-flex justify-content-between d-flex--gap">
              <div className="linkitem-collection w-100">
                {files.map((file) => (
                  <Linkitem
                    handleLink={file.visibility === 1 ? async () => await downloadDocJustImmo(file) : () => downloadDoc(file.url, file.headline)}
                    icon={<IconDocument colorDetails="#1E3163" />}
                    headline={file.headline}
                    text={file.text}
                    showArrow={false}
                    key={file.url}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {!isMobile && files.length > 0 && (
          <>
            <div className="w-desktop--center">
              <h2>Meine Dokumente</h2>
              <div className="d-flex justify-content-between d-flex--gap">
                <div className="linkitem-collection w-50">
                  {firstHalf.map((file) => (
                    <Linkitem
                      handleLink={file.visibility === 1 ? async () => await downloadDocJustImmo(file) : () => downloadDoc(file.url, file.headline)}
                      icon={<IconDocument colorDetails="#1E3163" />}
                      headline={file.headline}
                      text={file.text}
                      showArrow={false}
                      key={file.url}
                    />
                  ))}
                </div>
                <div className="linkitem-collection w-50">
                  {secondHalf.map((file) => (
                    <Linkitem
                      handleLink={file.visibility === 1 ? async () => await downloadDocJustImmo(file) : () => downloadDoc(file.url, file.headline)}
                      icon={<IconDocument colorDetails="#1E3163" />}
                      headline={file.headline}
                      text={file.text}
                      showArrow={false}
                      key={file.url}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-desktop--center">
          {' '}
          <Linkitem
            handleLink={handleSetShowAllDoksPageDialog}
            icon={<IconHerunterladen />}
            cssClasses="linkitem--blue linkitem--einzeilig-desktop"
            headline="Dokumente herunterladen"
            text="Sichern Sie ausgewählte Dokumente als ZIP."
          />
        </div>

        <PageDialog open={showAllDoksPageDialog} handleClose={handleSetShowAllDoksPageDialog} headline="Dokumente herunterladen">
          <div className="d-flex flex-col w-100 justify-content-center align-items-center mt-100rem">
            <div className="image--round position-relative" style={{ backgroundColor: '#3679ec' }}>
              <IconHerunterladen cssClasses="position-center" color="#fff" />
            </div>

            <span className="textlink mt-100rem" onClick={() => handleDownloadZip(true)}>
              Alle herunterladen (.zip)
            </span>
          </div>
          <div className="p-200rem">
            <h2 className="font-110">Dokumente auswählen</h2>
            {/* <ChecklistTableGroup
                  label={'Alle Dokumente'}
                  childOptions={checklistTableOptions}
                /> */}

            <ChecklistTableSingle label={'Alle Dokumente'} childList={checklistTableOptions} id={'immodrive-files'} />

            <span className="textlink" onClick={() => handleDownloadZip(false)}>
              Auswahl herunterladen (.zip)
            </span>

            {downloadError && <p className="form__fielderror">{downloadError}</p>}
          </div>
        </PageDialog>
        {files.length == 0 && (
          <p className="page__emptymessage w-desktop--center">
            Willkommen in immo-drive! Bald finden Sie hier alle Dokumente zu Ihrer Immobilie. Wenn Sie dazu Fragen haben, wenden Sie sich an Ihre Makler:in.
          </p>
        )}
      </MainContent>
    </>
  );
}

export default Immodrive;
