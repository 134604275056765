import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import headerImage from '../assets/images/sREAL-2024-Webhero-16zu9-1-5-Mariam-Handy.jpg';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import CardGridPlaceholderElement from '../components/Cards/CardGridPlaceholderElement';
import ImmoCard from '../components/Cards/ImmoCard';
import PageDialogSearchProfileForm from '../components/Dialogs/PageDialogSearchProfileForm';
import FormSelect from '../components/FormFields/FormSelect';
import SearchForm from '../components/Forms/SearchForm';
import Header from '../components/Header/Header';
import SearchProfilCreateLinkitem from '../components/Linkitems/SearchProfilCreateLinkitem';
import LoadingSpinner from '../components/LoadingSpinner';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

//ICONS
import IconArrowDown from '../components/Icons/IconArrowDown';

// STORE
import { setCachedSorting, setSearchFormExpanded } from '../reducers/searchForm';

import { sortOptions } from '../components/FormFields/util/formOptions';
import { immoSearchNoResultsText } from '../components/FormFields/util/formTexts';
import { parseImmoSreal } from '../util/immoObjectParser';

// HOOKS
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchImmoSearch } from '../hooks/immo/useFetchImmoSearch';
import { useHandleFavorite } from '../hooks/immo/useHandleFavorite';
import { useHandleCTAScroll } from '../hooks/immo/useHandleCTAScroll';
import IconHouseSearch from '../components/Icons/IconHouseSearch';
import { setPageDialogOpenSPForm } from '../reducers/searchProfileForm';
import SearchProfilFixedBar from '../components/fixedbar/SearchProfilFixedBar';

function Immosuche() {
  let currentSearchForm = useSelector((state) => state.searchForm);
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const { reloadFavsWithRealtyDetails } = useHandleFavorite();
  const { setRealtySearchList, handleShowMoreImmos, error } = useFetchImmoSearch();

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    // check if sorting really changed
    if (currentSearchForm.requestBody.sorting !== currentSearchForm.cachedSorting) {
      setRealtySearchList(true);
      dispatch(setCachedSorting(currentSearchForm.requestBody.sorting));
    }
  }, [currentSearchForm.requestBody.sorting]);

  useEffect(() => {
    if (isMobile) {
      dispatch(setSearchFormExpanded(true));
    }

    // first time visiting (no cached realties) - do search
    if (currentSearchForm.cachedImmoList.length === 0) {
      setRealtySearchList(true);
    }

    reloadFavsWithRealtyDetails();
  }, []);

  return (
    <>
      <MetaTags
        title={'Immobilien suchen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={
          'Sie sind auf der Suche, denn Sie wollen eine Wohnung kaufen oder ein Haus kaufen? my-sreal.at ist die Online-Plattform für Ihren Immobilienkauf. Einfach, transparent und digital suchen, finden und kaufen.'
        }
        imageName={'mysreal_hero_immosuche.jpg'}
      />
      {!isMobile ? (
        <Header cssClasses="header--default header--withimage header--immosuche" overlappedContent={true}>
          <img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
          <div className="header__content-wrapper header__content-wrapper--top">
            <h1 className="text-white mt-500rem mb-0">Immobiliensuche</h1>
            <h3 className="text-white">Kaufimmobilien aus ganz Österreich - hier werden Sie fündig!</h3>
          </div>
        </Header>
      ) : (
        <Header cssClasses="header--immosuche header--immosuche-mobil">
          <div className="header__content-wrapper header__content-wrapper--top">
            <h1>Immobiliensuche</h1>
          </div>
          <Accordion className="accordion accordion--searchform" defaultExpanded={currentSearchForm.searchFormExpanded}>
            <AccordionSummary expandIcon={<IconArrowDown color="#fff" />} aria-controls="panel1bh-content" id="panel1bh-header">
              <h2 className="pl-100rem pr-100rem">Suche anpassen</h2>
            </AccordionSummary>
            <AccordionDetails>
              <SearchForm forwardAction={setRealtySearchList} />
            </AccordionDetails>
          </Accordion>
        </Header>
      )}

      <MainContent cssClasses="main-content--immosuche">
        <div className="w-desktop--center">
          <div className="immosuche">
            <SearchForm forwardAction={setRealtySearchList} />
          </div>

          <SearchProfilFixedBar />

          <div className="pb-100rem" id="immoresult-list">
            {error ? (
              <>
                <h2 className="mb-200rem">Ergebnisse (0)</h2>
                <p>{error}</p>
              </>
            ) : (
              <>
                <div className="immosuche__ergebnisheader">
                  <h2>Ergebnisse ({currentSearchForm.cachedImmoListCount})</h2>
                  <FormSelect label={'Sortieren nach'} showBitteWaehlen={false} value={currentSearchForm.requestBody.sorting} formType={'searchForm'} id={'inputSorting'} options={sortOptions} />
                </div>

                {currentSearchForm.cachedImmoList.length > 0 ? (
                  <>
                    <div className="cardgrid">
                      {currentSearchForm.cachedImmoList.map((immo) => {
                        return <ImmoCard wohneinheiten={immo.wohneinheiten} retailObject={parseImmoSreal(immo)} key={immo.id} />;
                      })}

                      <CardGridPlaceholderElement array={currentSearchForm.cachedImmoList} />
                    </div>

                    {currentSearchForm.cachedImmoListCount > currentSearchForm.cachedImmoList.length && (
                      <div className="d-flex justify-content-center">
                        {app.formIsLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <span className="textlink" onClick={handleShowMoreImmos}>
                            Weitere Ergebnisse laden
                          </span>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <p>{immoSearchNoResultsText}</p>
                )}
              </>
            )}
          </div>
        </div>
      </MainContent>
      <PageDialogSearchProfileForm></PageDialogSearchProfileForm>
    </>
  );
}

export default Immosuche;
