import { useState } from 'react';
import IconCalculator from '../Icons/IconCalculator';
import Card from './Card';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import InfoDialog from '../Dialogs/InfoDialog';
import FinanzierungsRechnerForm from '../Forms/FinanzierungsRechnerForm';

function FinanzierungsCard() {
  const [openModal, setOpenModal] = useState(false);
  const handleInfoModal = () => {
    setOpenModal(!openModal);
  };

  const { isMobile } = useHandleScreenResize();

  return (
    <>
      <Card imagePath={require('../../assets/images/Finanzierenbild.jpg')} cardStyle={isMobile ? 'topImage' : 'topImage2ColsReversedLeft'} cssClasses="card--finanzierung">
        <h2 className={`${!isMobile ? 'font-220' : 'text-center'} headline--withinfomark`}>Berechnen Sie Ihre Finanzierung</h2>
        <FinanzierungsRechnerForm />
      </Card>

      <InfoDialog open={openModal} headline={'Finanzierung'} handleClose={handleInfoModal} />
    </>
  );
}

export default FinanzierungsCard;
